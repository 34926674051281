import { graphql } from "gatsby";
import styled from "@emotion/styled";
import * as React from "react";
import Gallery from "@browniebroke/gatsby-image-gallery";

import Layout from "../components/Layout";
import SectionContent from "../components/SectionContent";

const Station = styled.h3`
  margin-top: 2rem;
`;

// markup
const Page = ({ data, location }) => {
  const images = data.images.edges.map(({ node }, idx) => ({
    ...node.childImageSharp,
    // Generate name based on the index as caption.
    caption: ` אנדרטת הפלדה  | תמונה ${idx + 1}`,
  }));

  // Override some of Lightbox options to localise labels in French
  const lightboxOptions = {
    // imageLoadErrorMessage: "Impossible de charger cette image",
    nextLabel: "תמונה הבאה",
    prevLabel: "תמונה קודמת",
    closeLabel: "סגירה",
  };

  return (
    <Layout path={location.pathname} title="תחנות ההחלפה">
      <SectionContent title="תחנות ההחלפה">
        <p>בכל תחנה יהיה מנהל תחנה, סדרנים ומים.</p>
        <p>באחריות הרצים לרוץ בין התחנות עם מספיק מים.</p>
        <p>תחנת מים נוספות ימוקמו באמצע הדרך בין באר רבובה לפארק אשכול.</p>
        <p>בכניסה לקיבוץ כפר עזה (שער ראשי)</p>
        <p>באמצע הדרך בין החץ השחור לארז (מפגש בצמוד למסילת הרכבת)</p>
        <p>הסבר הגעה למיקום תחנות ההחלפה:</p>

        <Station>נקודת הזינוק &ndash; אנדרטת הפלדה:</Station>
        <p>
          <Gallery images={images} lightboxOptions={lightboxOptions} />
        </p>
        <p>קישור למיקום נקודת הזינוק:</p>
        <p>
          <a href="https://goo.gl/maps/zHik4o8CuwYuMu6P7">
            https://goo.gl/maps/zHik4o8CuwYuMu6P7
          </a>
        </p>

        <Station>תחנת מספר 1 &ndash; תלמי יוסף:</Station>
        <p>
          מיקום התחנה: בצומת &apos;פרי גן&apos; &ndash;&apos;תלמי יוסף&apos;
        </p>
        <p>
          הסבר הגעה: מכביש 232 פונים דרומה לכביש 2211. נוסעים 2.3 ק&quot;מ.
          בכיכר פונים שמאלה (יציאה שלישית) לכביש 2200, ממשיכים כ2.4 ק&quot;מ עד
          צמת T (ימינה לתלמי יוסף, שמאלה לפרי גן). התחנה ממוקמת מעבר לצמת בסמוך
          לדרך השדות
        </p>
        <p>קישור למיקום התחנה:</p>
        <p>
          <a href="https://goo.gl/maps/9Hno4Q4yLvP2">
            https://goo.gl/maps/9Hno4Q4yLvP2
          </a>
        </p>
        <Station>תחנת החלפה מספר 2 &ndash; גבולות:</Station>
        <p>מיקום התחנה: ברחבת מצפה גבולות</p>
        <p>קישור למיקום התחנה:</p>
        <p>
          <a href="https://goo.gl/maps/Bf6u78sufgk">
            https://goo.gl/maps/Bf6u78sufgk
          </a>
        </p>
        <Station>תחנת החלפה מספר 3 &ndash; צאלים:</Station>
        <p>מיקום התחנה: בחורשת הזיתים הצמודה לשער הכניסה לקיבוץ צאלים</p>
        <p>הסבר הגעה: מכביש 232 פונים דרומה לכביש 222.</p>
        <p>קישור למיקום התחנה:</p>
        <p>
          <a href="https://goo.gl/maps/Deb5BneQaWu">
            https://goo.gl/maps/Deb5BneQaWu
          </a>
        </p>
        <Station>תחנת החלפה מספר 4 &ndash; באר רבובה:</Station>
        <p>מיקום התחנה: ברחבת העפר הצמודה לבאר רבובה</p>
        <p>
          הסבר הגעה: מכביש 232 פונים דרומה לכביש 222. נוסעים 9.7 ק&quot;מ ופונים
          שמאלה בדרך עפר (מול שלט המפנה ימינה למתקן &apos;דיה&apos;). מצמת צאלים
          נוסעים לכיוון צפון מערב כ-2.6 ק&quot;מ ופונים ימינה בדרך העפר.
        </p>
        <p>
          על דרך העפר ממשיכים 3.6 ק&quot;מ עד למאגר מים מימין, וממשיכים ע&quot;פ
          השילוט במקום.
        </p>
        <p>
          <a href="https://goo.gl/maps/e1kNdfJnNpz">
            https://goo.gl/maps/e1kNdfJnNpz
          </a>
        </p>
        <Station>תחנת החלפה מספר 5 &ndash; אשכול:</Station>
        <p>מיקום התחנה: מתחת ל&apos;גשר אברהם&apos;</p>
        <p>
          הסבר הגעה: יורדים מכביש 241 בדרך המובילה ל&apos;דרך נוף הבשור&apos;.
          לבאים מכיוון מערב (צומת מעון), יורדים מהכביש בשלט המכוון לדרך הנוף, אך
          במקום לפנות ימינה לדרך, ממשיכים ישר לכיוון הגשר. לבאים מכיוון מזרח
          (צומת אורים) פונים מיד לאחר הגשר ימינה לדרך לבנה המתעקלת לכיוון הגשר.
        </p>
        <p>קישור למיקום התחנה:</p>
        <p>
          <a href="https://goo.gl/maps/icPra28cj4C2">
            https://goo.gl/maps/icPra28cj4C2
          </a>
        </p>
        <Station>תחנת החלפה מספר 6 &ndash; סכר רעים:</Station>
        <p>מיקום התחנה: מפגש דרך השפד&quot;ן עם נחל הבשור על סכר רעים.</p>
        <p>
          הסבר הגעה: למגיעים מכביש 241 (בין צמת מעון לבין צמת אורים), פונים
          צפונה לדרך השפד&quot;ן (דרך אספלט ברובה) כ- 1 ק&quot;מ מזרחית לצמת
          מעון, מול מתקן מקורות &apos;תחנת אברהם&apos; כ-4 ק&quot;מ עד לסכר
          רעים. למגיעים מכביש 234 (בין צמת אורים לבין צמת רעים) פונים לכיוון
          דרום מערב (ניצב לכביש), כ 1 ק&quot;מ צפונית לחות טל אור, על דרך לבנה כ
          &ndash; 2 ק&quot;מ נסיעה עד לסכר.
        </p>
        <p>
          <a href="https://goo.gl/maps/kcoGHbo3atG2">
            https://goo.gl/maps/kcoGHbo3atG2
          </a>
        </p>
        <Station>תחנת החלפה מספר 7 &ndash; גשר רעים:</Station>
        <p>מיקום התחנה: מתחת לגשר רעים (העובר על כביש 232).</p>
        <p>
          הסבר הגעה: יורדים מכביש 232 כ-300 מטר דרומית לגשר בדרך המובילה
          לתל-גמה, ופונים ימינה (צפונה) בחורשת האקליפטוסים עד לחניון עם שולחנות
          קק&quot;ל. משם יורדים ברגל בזהירות אל ערוץ נחל הבשור (התחנה ממוקמת
          מימן)
        </p>
        <p>
          <a href="https://goo.gl/maps/CquDQgzNbVN2">
            https://goo.gl/maps/CquDQgzNbVN2
          </a>
        </p>
        <Station>
          תחנת החלפה מספר 8 &ndash; בארי &ndash; חנות &apos;לה-מדווש&apos;:
        </Station>
        <p>מיקום התחנה: ברחבת החניה של חנות לה-מדווש בכניסה לקיבוץ בארי.</p>
        <p>
          <a href="https://goo.gl/maps/zeSHyw5wj772">
            https://goo.gl/maps/zeSHyw5wj772
          </a>
        </p>
        <Station>
          תחנת החלפה מספר 9 &ndash; נחל עוז &ndash; &apos;פינת דניאל&apos;:
        </Station>
        <p>
          מיקום התחנה: &apos;פינת המרגוע ע&quot;ש דניאל&apos; בחורשת האקליפטוסים
          שבין כביש לכניסה לקיבוץ נחל עוז לכביש הראשי (כביש 25).
        </p>
        <p>
          הסבר הגעה: מצמת סעד (כיכר על כביש 25 וכביש 232) פונים מערבה לכיוון נחל
          עוז 2.8 ק&quot;מ. פונים לכביש הגישה המוביל לנחל עוז, ומיד פונים שוב
          שמאלה לכביש העוקף ושוב שמאלה בדרך עפר לחורשת אקליפטוסים.
        </p>
        <p>
          <a href="https://goo.gl/maps/56HzxTqzejx">
            https://goo.gl/maps/56HzxTqzejx
          </a>
        </p>
        <Station>תחנת החלפה מספר 10 &ndash; &apos;חץ שחור&apos;:</Station>
        <p>מיקום התחנה: בחורשה הצמודה לאנדרטת &apos;חץ שחור&apos;</p>
        <p>
          הסבר הגעה: על כביש 232 , 800 מטר מערבית למפלסים; 4 ק&quot;מ צפונית
          לכפר עזה פונים מערבה (ממפלסים ימינה, מכפר עזה שמאלה) ובצמוד לתחנת
          שאיבה של מקורות פונים ימינה ומיד שמאלה.
        </p>
        <p>
          <a href="https://goo.gl/maps/1Pim6h5SQKz">
            https://goo.gl/maps/1Pim6h5SQKz
          </a>
        </p>
        <Station>תחנת החלפה מספר 11 &ndash; קיבוץ ארז מפגש נחל שקמה:</Station>
        <p>מיקום התחנה: מפגש נחל שקמה צפונית לקיבוץ ארז</p>
        <p>
          <a href="https://goo.gl/maps/veudYKDc5us86Eye8">
            https://goo.gl/maps/veudYKDc5us86Eye8
          </a>
        </p>
        <Station>מתחם סיום חוף זיקים:</Station>
        <p>
          <a href="https://goo.gl/maps/ANJWrzZ455G2">
            https://goo.gl/maps/ANJWrzZ455G2
          </a>
        </p>
      </SectionContent>
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query ImagesForStations {
    images: allFile(
      filter: { relativeDirectory: { eq: "plada-monument" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
